import { Box, Heading, List, ListIcon, ListItem } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { FaCheck } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import Button from "../components/common/Button"
import { usePlayer } from "../hooks/query"

const PremiumPage: FunctionComponent = () => {
    const player = usePlayer()

    const navigate = useNavigate()

    return (
        <Box p="4">
            <Heading as="h1">Become Premium</Heading>

            <Heading as="h2" size="md" my="4">
                Why ?
            </Heading>

            <List>
                <ListItem>
                    <ListIcon as={FaCheck} color="green.500" />
                    Create a group to play with your friends
                </ListItem>
                <ListItem>
                    <ListIcon as={FaCheck} color="green.500" />
                    Sleep well : 12 offline hours instead of 8
                </ListItem>
                <ListItem>
                    <ListIcon as={FaCheck} color="green.500" />
                    Your votes counts twice
                </ListItem>
                <ListItem>
                    <ListIcon as={FaCheck} color="green.500" />
                    Special color in chat and all characters allowed in username
                </ListItem>
                <ListItem>
                    <ListIcon as={FaCheck} color="green.500" />
                    ... and more to come
                </ListItem>
            </List>

            <Heading as="h2" size="md" my="4">
                How ?
            </Heading>

            <List>
                <ListItem>
                    <ListIcon as={FaCheck} color="green.500" />
                    Click on the button below
                </ListItem>
                <ListItem>
                    <ListIcon as={FaCheck} color="green.500" />
                    Pay 10€
                </ListItem>
                <ListItem>
                    <ListIcon as={FaCheck} color="green.500" />
                    Enjoy your premium features
                </ListItem>
            </List>

            <Button mt="8" onClick={() => navigate("/checkout")}>Become Premium</Button>
        </Box>
    )
}

export default PremiumPage
