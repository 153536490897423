import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './theme'
import { RootStoreProvider } from './store'
import Fonts from './components/layout/Fonts'
import { queryClient } from './query_client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RootStoreProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ChakraProvider theme={theme}>
          <Fonts />
          <App />
        </ChakraProvider>
      </QueryClientProvider>
    </RootStoreProvider>
  </React.StrictMode>
)
