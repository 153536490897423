import { BoxProps, Center, Stack, Text } from "@chakra-ui/react"
import { FunctionComponent, useEffect, useRef } from "react"
import { PlayerActivity } from "../../api/player"
import Activity from "./Activity"
import Loading from "../common/Loading"
import { observer } from "mobx-react-lite"
import { useStore } from "../../store"

interface ActivitiesProps extends BoxProps {
    list: PlayerActivity[]
    maxHeight?: string
    initialized?: boolean
}

const Activities: FunctionComponent<ActivitiesProps> = observer(({ list, initialized, ...props }) => {
    const boxRef = useRef<HTMLDivElement>(null)
    const { mapStore } = useStore()

    // scroll when new activity is added
    useEffect(() => {
        // scroll to bottom if we're already at the bottom
        if (
            boxRef.current &&
            boxRef.current.scrollTop + boxRef.current.clientHeight + 100 > boxRef.current.scrollHeight
        ) {
            boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
        }
    }, [list.length])

    // scroll to bottom at the start
    useEffect(() => {
        if (initialized && boxRef.current) {
            boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
        }
    }, [initialized])

    useEffect(() => {
        if (initialized && boxRef.current) {
            boxRef.current.scrollTo(0, boxRef.current.scrollHeight)
        }
    }, [mapStore.selectedTile])

    

    return (
        <Stack gap={0} ref={boxRef} overflowY="auto" minHeight="270px" pr="1" fontSize="sm" {...props}>
            {!initialized && <Center minHeight="270px" maxHeight={props.maxHeight}><Loading /></Center>}
            {initialized && list.length === 0 && <Text>No log yet</Text>}
            {list.map((activity, index) => (
                <Activity key={index} activity={activity} />
            ))}
        </Stack>
    )
})

export default Activities
