import { useState, useEffect, useRef, useCallback } from "react"
import { useBreakpoint } from "@chakra-ui/react"
import { QueryClient } from "@tanstack/react-query"
import { Resources } from "../api/game"
import { PlayerResources, ResourceCost } from "../types"
import { getKeys } from "../utils"
import { useGameId, useResources } from "./query"
import { useStore } from "../store"
import { queryClient } from "../query_client"
import { watch, unwatch } from "./visibility_observer"

const _hasEnoughResources = (playerResources: PlayerResources | undefined, resources: ResourceCost) => {
    if (!playerResources) return false
    if (resources.wood && playerResources.wood < resources.wood) return false
    if (resources.iron && playerResources.iron < resources.iron) return false
    if (resources.soldiers && playerResources.soldiers < resources.soldiers) return false
    if (resources.workers && playerResources.workers < resources.workers) return false
    return true
}

/** check if user has enough resources */
export const hasEnoughResources = (resources: ResourceCost) => {
    const userResources = useResources()
    return _hasEnoughResources(userResources, resources)
}

// for optimistic update
interface SpendResourcesParams {
    gameId: number
    resources: ResourceCost
}

/** spend resources on UI only (not on server)
 * used to update UI optimistically
 */
export const UISpendResources = ({ gameId, resources }: SpendResourcesParams) => {
    const userResources: PlayerResources | undefined = queryClient.getQueryData(["gatherResources", gameId])
    const enough = _hasEnoughResources(userResources, resources)
    if (!enough) return false
    queryClient.setQueryData(["gatherResources", gameId], (old: Resources | undefined) => {
        if (old === undefined) return
        const newResources = { ...old }
        for (const resource of getKeys(resources)) {
            newResources[resource]! -= resources[resource] || 0
        }
        return newResources
    })
}

export const useIsMobile = () => {
    const breakpoint = useBreakpoint({ ssr: false })
    return ["base", "sm", "md"].includes(breakpoint)
}

export const useIsFirstMount = () => {
    const isMountRef = useRef(true)
    useEffect(() => {
        isMountRef.current = false
    }, [])
    return isMountRef.current
}

export const usePrevious = (value: number | boolean) => {
    const ref = useRef({
        value: value,
        prev: null,
    })

    const current = ref.current.value

    // if the value passed into hook doesn't match what we store as "current"
    // move the "current" to the "previous"
    // and store the passed value as "current"
    if (value !== current && value !== null) {
        ref.current = {
            value: value,
            // @ts-ignore
            prev: current,
        }
    }
    // return the previous value only
    return ref.current.prev
}

/** check if an element is visible */
// export function useIsVisible(ref: React.RefObject<HTMLElement>) {
//     const [isIntersecting, setIntersecting] = useState(false)

//     useEffect(() => {
//         const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))
//         if(ref.current)
//             observer.observe(ref.current)
//         return () => {
//             console.log("disconnect")
//             observer.disconnect()
//             setIntersecting(false)
//         }
//     }, [ref])

//     return isIntersecting
// }

export function useIsVisible(nodeRef: React.RefObject<HTMLElement>, { once = false } = {}) {
    const [isVisible, setVisible] = useState(false)

    const handleVisibilityChange = useCallback(
        (entriesOrEntry: IntersectionObserverEntry[] | IntersectionObserverEntry) => {
            const entries = Array.isArray(entriesOrEntry) ? entriesOrEntry : [entriesOrEntry];
            const [entry] = entries;

            setVisible(entry.isIntersecting)

            if (entry.isIntersecting && once) {
                unwatch(nodeRef.current)
            }
        },
        [nodeRef, once]
    )

    useEffect(() => {
        if (nodeRef.current) {
            return watch(nodeRef.current, handleVisibilityChange)
        }
    }, [nodeRef, handleVisibilityChange])

    return isVisible
}

export default useIsVisible

const DEBUG = import.meta.env.VITE_DEBUG

export const useIsDebug = () => {
    const { userStore } = useStore()
    return userStore.player?.isAdmin || DEBUG == "true"
}
