import { Box, HStack, Icon, Link, Text, useTheme } from "@chakra-ui/react"
import React, { FunctionComponent } from "react"
import { PlayerActivity, PlayerActivityType } from "../../api/player"
import { useStore } from "../../store"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { useGameId } from "../../hooks/query"
import { t } from "i18next"
import PlayerName from "../common/PlayerName"
import DateComponent from "../common/DateComponent"
import Number from "../common/Number"
import MapStore from "../../store/MapStore"
import { default as RoleComponent } from "../common/Role"
import { Factions, ResourceType, Role } from "../../types"
import GameIcon from "../common/GameIcon"

interface ActivityProps {
    activity: PlayerActivity
    showTime?: boolean
}

const coordWrapper = (mapStore: MapStore, gameId: number, navigate: NavigateFunction, x: number, y: number) => {
    return (
        <Link
            fontWeight="bold"
            onClick={() => {
                mapStore.setSelectedTile(x, y)
                navigate(`/play/${gameId}/world`)
            }}
        >
            {x}:{y}
        </Link>
    )
}

interface FactionProps {
    faction: Factions
    children: React.ReactNode
}
const FactionWrapper: FunctionComponent<FactionProps> = ({ faction, children }) => {
    const theme = useTheme()
    const factionColor = theme.app.factions[faction!] || "orange.300"
    return (
        <Text as="span" bgColor={factionColor} p={1}>
            {children}
        </Text>
    )
}

const Activities: {
    [key in PlayerActivityType]: (
        activity: PlayerActivity,
        mapStore: MapStore,
        gameId: number,
        navigate: NavigateFunction
    ) => React.ReactNode
} = {
    [PlayerActivityType.BUILDING_BUILT]: (activity) => (
        <Text as="span">
            built a{" "}
            <Text as="span" fontWeight="bold">
                {t(`buildings.${activity.name}.name`)}
            </Text>
        </Text>
    ),
    [PlayerActivityType.BUILDING_UPGRADED]: (activity) => (
        <Text as="span">
            upgraded{" "}
            <Text as="span" fontWeight="bold">
                {t(`buildings.${activity.name}.title`)}
            </Text>{" "}
            to level {activity.data.level}
        </Text>
    ),
    [PlayerActivityType.HQ_UPGRADED]: (activity) => (
        <Text as="span">upgraded its HQ to level {activity.data.level}</Text>
    ),
    [PlayerActivityType.IMPROVEMENT_WORKERS_SENT]: (activity, mapStore, gameId, navigate) => (
        <Text as="span">
            sent <Number value={activity.amount!} color="orange.700" /> worker{activity.amount! > 1 ? "s" : ""} to
            improve {coordWrapper(mapStore, gameId, navigate, activity.x!, activity.y!)}
        </Text>
    ),
    [PlayerActivityType.DISMANTLE_WORKERS_SENT]: (activity, mapStore, gameId, navigate) => (
        <Text as="span">
            dismantled {activity.amount} worker{activity.amount! > 1 ? "s" : ""} from{" "}
            {coordWrapper(mapStore, gameId, navigate, activity.x!, activity.y!)}
        </Text>
    ),
    [PlayerActivityType.FORTIFICATION_WORKERS_SENT]: (activity, mapStore, gameId, navigate) => (
        <Text as="span">
            sent {activity.amount} worker{activity.amount! > 1 ? "s" : ""} to fortify{" "}
            {coordWrapper(mapStore, gameId, navigate, activity.x!, activity.y!)}
        </Text>
    ),
    [PlayerActivityType.EVENT_PROJECT_WORKERS_SENT]: (activity) => (
        <Text as="span">
            sent <Number value={activity.amount!} color="orange.700" /> worker{activity.amount! > 1 ? "s" : ""} to event
            project{" "}
            <Text as="span" fontWeight="bold">
                {t(`projects.${activity.name}.title`)}
            </Text>
        </Text>
    ),
    [PlayerActivityType.PAGE_LOADED]: (activity) => <Text as="span">loaded the game</Text>,
    [PlayerActivityType.PROJECT_WORKERS_SENT]: (activity) => (
        <Text as="span">
            sent <Number value={activity.amount!} color="orange.700" /> worker{activity.amount! > 1 ? "s" : ""} to
            project{" "}
            <Text as="span" fontWeight="bold">
                {t(`permanentProjects.${activity.name}.title`)}
            </Text>
        </Text>
    ),
    [PlayerActivityType.SOLDIERS_ATTACK]: (activity, mapStore, gameId, navigate) => (
        <Text as="span">
            sent <Number value={activity.amount!} color="orange.700" /> soldier{activity.amount! > 1 ? "s" : ""} to
            attack {coordWrapper(mapStore, gameId, navigate, activity.x!, activity.y!)}{" "}
            {activity.data.captured && (
                <>
                    and captured it from{" "}
                    <FactionWrapper faction={activity.data.previous_faction || "NEUTRAL"}>
                        {activity.data.previous_faction || "NEUTRAL"}
                    </FactionWrapper>
                </>
            )}
        </Text>
    ),
    [PlayerActivityType.SOLDIERS_DEFEND]: (activity, mapStore, gameId, navigate) => (
        <Text as="span">
            sent <Number value={activity.amount!} color="orange.700" /> soldier{activity.amount! > 1 ? "s" : ""} to
            defend {coordWrapper(mapStore, gameId, navigate, activity.x!, activity.y!)}
        </Text>
    ),
    [PlayerActivityType.SUPPORT_SENT]: (activity, mapStore, gameId, navigate) => {
        const killed = activity.name === ResourceType.GUARDIAN ? ResourceType.KNIGHT : ResourceType.GUARDIAN
        return (
            <Text as="span">
                sent its{" "}
                <GameIcon
                    name={activity.name as ResourceType}
                    position="relative"
                    top={activity.name === ResourceType.KNIGHT ? "5px" : "0"}
                />{" "}
                to {coordWrapper(mapStore, gameId, navigate, activity.x!, activity.y!)}{" "}
                {activity.data.kill ? (
                    <>
                        and killed a{" "}
                        <FactionWrapper faction={activity.data.killed_faction}>
                            {activity.data.killed_faction}
                            <GameIcon name={killed} top={activity.name === ResourceType.KNIGHT ? "5px" : "0"} />
                        </FactionWrapper>
                    </>
                ) : (
                    ""
                )}
            </Text>
        )
    },
    [PlayerActivityType.LOOT]: (activity, mapStore, gameId, navigate) => (
        <Text as="span">
            looted {coordWrapper(mapStore, gameId, navigate, activity.x!, activity.y!)} for {activity.amount}{" "}
            <GameIcon name={ResourceType.VICTORY_POINTS} />
        </Text>
    ),
    [PlayerActivityType.ROLE_CHANGED]: (activity) => (
        <Text as="span">
            changed role to <RoleComponent name={activity.name as Role} />
        </Text>
    ),
    [PlayerActivityType.SPEC_PICKED]: (activity) => (
        <Text as="span">
            picked spec{" "}
            <Text as="span" fontWeight="bold">
                {activity.name}
            </Text>
        </Text>
    ),
    [PlayerActivityType.PING]: (activity) => <Text as="span">pinged</Text>,
    [PlayerActivityType.EVENT_PROJECT_COMPLETED]: (activity) => (
        <Text as="span">
            completed event project{" "}
            <Text as="span" fontWeight="bold">
                {t(`projects.${activity.name}.title`)}
            </Text>
        </Text>
    ),
    [PlayerActivityType.PROJECT_COMPLETED]: (activity) => (
        <Text as="span">
            completed project{" "}
            <Text as="span" fontWeight="bold">
                {t(`permanentProjects.${activity.name}.title`)}
            </Text>
        </Text>
    ),
}

const Activity: FunctionComponent<ActivityProps> = ({ activity, showTime = true }) => {
    const { mapStore } = useStore()
    const navigate = useNavigate()

    if (!Activities[activity.type]){
        console.warn("unknown activity type", activity.type)
        return null
    }

    return (
        <Box>
            <PlayerName playerId={activity.player.id} username={activity.player.username} faction={activity.faction} />{" "}
            {Activities[activity.type](activity, mapStore, activity.game_id!, navigate)}
            {showTime && (
                <Text
                    display="inline-block"
                    as="span"
                    fontSize="xs"
                    color="orange.200"
                    whiteSpace="nowrap"
                    float="right"
                    ps="2"
                >
                    <DateComponent date={activity.created_at * 1000} />
                </Text>
            )}
        </Box>
    )
}

export default Activity
