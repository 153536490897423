import { Box, HStack, Heading, SimpleGrid, Stack, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { useFaction, useGameId } from "../../hooks/query"
import { Role } from "../../types"
import { getEntries } from "../../utils"
import Panel from "../common/Panel"
import { default as RoleComponent } from "../common/Role"
import Player from "./Player"
import RoleSelect from "./RoleSelect"

interface PlayersProps {}
const Players: FunctionComponent<PlayersProps> = ({}) => {
    const gameId = useGameId()
    const data = useFaction()

    if (!data) {
        return null
    }

    // count the number of players in each role
    const roleCounts: { [key in Role]?: number } = {}
    data.details?.members?.forEach((member) => {
        if (!roleCounts[member.role]) {
            roleCounts[member.role] = 0
        }
        roleCounts[member.role]!++
    })
    // sort roles by count
    Object.entries(roleCounts).sort((a, b) => b[1] - a[1])

    // sort members by name
    data.details?.members?.sort((a, b) => b.hq_level - a.hq_level)

    return (
        <SimpleGrid columns={[1, 2]}>
            <Panel>
                <RoleSelect />
                <Stack mt="4">
                    <Heading as="h3" size="md">
                        Your faction roles
                    </Heading>
                    {getEntries(roleCounts).map(([role, count]) => (
                        <HStack key={role}>
                            <RoleComponent name={role} fontSize="sm" pr="2" />
                            <Text> : {count}</Text>
                        </HStack>
                    ))}
                </Stack>
            </Panel>
            <Panel>
                <Heading as="h3" size="md" mb="2">
                    Players
                </Heading>
                <Box overflow="auto" maxHeight="500px">
                    <Table variant="simple" size="sm" overflow="auto">
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>HQ level</Th>
                                <Th>Role</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.details?.members?.map((member) => (
                                <Player key={member.id} {...member} />
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Panel>
        </SimpleGrid>
    )
}

export default Players
