import {
    Box,
    Button as ChakraButton,
    Center,
    HStack,
    Heading,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    useDisclosure,
} from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useEffect } from "react"
import Activities from "../components/activities/Activities"
import Button from "../components/common/Button"
import Loading from "../components/common/Loading"
import Panel from "../components/common/Panel"
import EventProject from "../components/projects/EventProject"
import FinishedProject from "../components/projects/FinishedProject"
import PastProjects from "../components/projects/PastProjects"
import ProjectsTree from "../components/projects/ProjectsTree"
import { useProjects } from "../hooks/query"
import { useStore } from "../store"
import { MdFilterAlt } from "react-icons/md"

const ProjectsActivities = observer(() => {
    const { projectsStore, settingsStore } = useStore()
    const filteredActivities = projectsStore.projectsActivities?.filter(
        (a) => a.amount === null || a.amount! >= settingsStore.projectsLogQuantity
    )
    return (
        <Panel>
            <HStack justify="space-between">
                <Heading as="h3" size="md" mb="2">
                    Logs
                </Heading>
                <Menu size="sm">
                    <MenuButton as={ChakraButton} size="sm">
                        <MdFilterAlt />
                    </MenuButton>
                    <MenuList>
                        <MenuOptionGroup
                            defaultValue={"" + settingsStore.projectsLogQuantity}
                            title="Quantity"
                            type="radio"
                            onChange={(value) => settingsStore.setProjectsLogQuantity(parseInt(value as string, 10))}
                        >
                            <MenuItemOption value={"1"}>All</MenuItemOption>
                            <MenuItemOption value={"5"}>{">"} 5</MenuItemOption>
                            <MenuItemOption value={"50"}>{">"} 50</MenuItemOption>
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>
            </HStack>
            <Box p="1">
                <Activities
                    list={filteredActivities}
                    maxHeight="35rem"
                    initialized={projectsStore.projectsActivitiesInitialized}
                />
            </Box>
        </Panel>
    )
})

const Projects: FunctionComponent = observer(() => {
    const { data: projects, isLoading, isError } = useProjects()
    const events = projects?.event.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    const { userStore, projectsStore } = useStore()
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        projectsStore.startProjectsSocket()
        return () => {
            projectsStore.stopProjectsSocket()
        }
    }, [userStore.player?.userId])

    return (
        <Box>
            {isLoading && <Loading />}
            {isError && <Box>Error</Box>}

            <HStack alignItems="flex-start" flexWrap="wrap">
                <Box flexGrow={1} flex={2}>
                    <Heading>Event projects</Heading>

                    <Text my="2">
                        Event projects are time-limited, and you are in competition with other factions. The first
                        faction get 100% of the bonus, the second 50% and the third 25%.
                    </Text>
                    <SimpleGrid columns={[1, 1, 1, 2]} spacing={4}>
                        {events && events.map((project) => <EventProject {...project} key={project.id} />)}
                    </SimpleGrid>

                    <SimpleGrid columns={[1, 1, 1, 2]} spacing={4} mt="4">
                        {projects?.last.map((project) => (
                            <FinishedProject {...project} key={project.id} />
                        ))}
                    </SimpleGrid>
                    <Center mt="2">
                        <Button onClick={onOpen} title="Show all previous projects">
                            Show all previous projects
                        </Button>
                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Previous projects</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <PastProjects />
                                </ModalBody>

                                <ModalFooter>
                                    <Button mr={3} onClick={onClose}>
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </Center>
                </Box>
                <Box minWidth="20rem" flexGrow={1} flex={1} maxHeight="fit-content">
                    <ProjectsActivities />
                </Box>
            </HStack>

            <Heading>Development tree</Heading>
            <ProjectsTree />
        </Box>
    )
})

export default Projects
