import { Box, Center, Flex, HStack, Heading, Image, SimpleGrid, Stack, Text, Wrap, WrapItem } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { BsCheckSquareFill, BsSquareFill } from "react-icons/bs"
import { FaSortAmountDownAlt } from "react-icons/fa"
import Button from "../components/common/Button"
import Panel from "../components/common/Panel"
import BuildModal from "../components/hq/BuildModal"
import { BuildingComponent, BuildingPlaceholder } from "../components/hq/Buildings"
import HQLevelUp from "../components/hq/HQLevelUp"
import { useHQInfo } from "../hooks/query"
import { useStore } from "../store"
import { BuildingOrders } from "../store/SettingsStore"
import { UserBuilding } from "../types"
// let interval: any = null

const HQ: FunctionComponent = observer(() => {
    const { isLoading, error, data } = useHQInfo()
    const { settingsStore } = useStore()

    const condensed = !data || data.buildings.length <= 6 ? false : settingsStore.hqViewCondensed
    const sortOrder = !data || data.buildings.length <= 6 ? BuildingOrders.CREATED : settingsStore.hqViewOrder

    // const [count, setCount] = useState(0)

    // useEffect(() => {
    //     interval = setInterval(() => {
    //         setCount((count) => count + 1)
    //     }, 4000)
    //     return () => clearInterval(interval)
    // }, [])
    return (
        <Box>
            {/* <Number value={count} animated /> */}
            {data && (
                <>
                    <Center>
                        <Panel
                            w={condensed ? "full" : "fit-content"}
                            variant="withBorder"
                            animated
                            animKey={data.hq.level}
                        >
                            <Flex justifyContent={"space-between"} wrap={["wrap", "wrap", "nowrap"]}>
                                <Flex
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    width={["full", "full", "fit-content"]}
                                    mb={[1, 1, 0]}
                                >
                                    <Image
                                        src="/images/hq.png"
                                        w={condensed ? "120px" : "180px"}
                                        h={condensed ? "120px" : "180px"}
                                        objectFit={"cover"}
                                        aria-hidden
                                    />
                                </Flex>

                                <Stack flexGrow={1} justifyContent={"space-between"} ps="2">
                                    <HStack alignItems={"stretch"}>
                                        <Flex justifyContent={"space-between"} flexDir={"column"} flexGrow={1} me="2">
                                            <HStack
                                                justifyContent={"space-between"}
                                                borderBottom={"2px solid"}
                                                borderColor="orange.600"
                                                wrap="wrap"
                                            >
                                                <Heading as="h4">Village</Heading>

                                                <Text variant={"discreet"}>level {data.hq.level}</Text>
                                            </HStack>
                                            <Box>
                                                Buildings : {data.buildings?.length || 0} /{" "}
                                                {data.hq.maxNumberOfBuildings}
                                            </Box>
                                        </Flex>
                                    </HStack>
                                    <Flex justifyContent={"end"}>
                                        <HStack gap={0} justifyContent={"center"} alignItems={"center"}>
                                            <Flex
                                                border={"2px solid"}
                                                borderColor="orange.600"
                                                borderStyle={"outset"}
                                                borderRadius="sm"
                                                boxShadow={"md"}
                                            >
                                                <HQLevelUp />
                                            </Flex>
                                        </HStack>
                                    </Flex>
                                </Stack>
                            </Flex>
                        </Panel>
                    </Center>
                    {data.buildings.length > 6 && (
                        <Center>
                            <Wrap spacing="0">
                                <WrapItem>
                                    <Button
                                        size="sm"
                                        m="0.5"
                                        leftIcon={condensed ? <BsCheckSquareFill /> : <BsSquareFill />}
                                        colorScheme="blue"
                                        onClick={() => settingsStore.setHqViewCondensed(!settingsStore.hqViewCondensed)}
                                    >
                                        Condensed view
                                    </Button>
                                </WrapItem>
                                <WrapItem>
                                    <Button
                                        size="sm"
                                        m="0.5"
                                        leftIcon={<FaSortAmountDownAlt />}
                                        tooltip="Sort by building creation date"
                                        colorScheme={sortOrder == BuildingOrders.CREATED ? "green" : "blue"}
                                        onClick={() => settingsStore.setHqViewOrder(BuildingOrders.CREATED)}
                                    >
                                        date
                                    </Button>
                                </WrapItem>
                                <WrapItem>
                                    <Button
                                        size="sm"
                                        m="0.5"
                                        leftIcon={<FaSortAmountDownAlt />}
                                        tooltip="Sort by building type"
                                        colorScheme={sortOrder == BuildingOrders.TYPE ? "green" : "blue"}
                                        onClick={() => settingsStore.setHqViewOrder(BuildingOrders.TYPE)}
                                    >
                                        type
                                    </Button>
                                </WrapItem>
                                <WrapItem>
                                    <Button
                                        size="sm"
                                        m="0.5"
                                        leftIcon={<FaSortAmountDownAlt />}
                                        tooltip="Sort by wood price"
                                        colorScheme={sortOrder == BuildingOrders.WOOD_PRICE ? "green" : "blue"}
                                        onClick={() => settingsStore.setHqViewOrder(BuildingOrders.WOOD_PRICE)}
                                    >
                                        wood price
                                    </Button>
                                </WrapItem>
                                <WrapItem>
                                    <Button
                                        size="sm"
                                        m="0.5"
                                        leftIcon={<FaSortAmountDownAlt />}
                                        tooltip="Sort by iron price"
                                        colorScheme={sortOrder == BuildingOrders.IRON_PRICE ? "green" : "blue"}
                                        onClick={() => settingsStore.setHqViewOrder(BuildingOrders.IRON_PRICE)}
                                    >
                                        iron price
                                    </Button>
                                </WrapItem>
                            </Wrap>
                        </Center>
                    )}
                    <BuildingsList buildings={data.buildings} max={data.hq.maxNumberOfBuildings} />
                </>
            )}
        </Box>
    )
})

interface BuildingsListProps {
    buildings: UserBuilding[]
    max: number
}
const BuildingsList: FunctionComponent<BuildingsListProps> = observer(({ buildings, max }) => {
    const { settingsStore } = useStore()
    const condensed = buildings.length <= 6 ? false : settingsStore.hqViewCondensed
    const sortOrder = buildings.length <= 6 ? BuildingOrders.CREATED : settingsStore.hqViewOrder
    
    const sortedBuildings = buildings.sort((a, b) => {
        switch (sortOrder) {
            case BuildingOrders.CREATED:
                return a.id - b.id
            case BuildingOrders.TYPE:
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return a.level - b.level
            case BuildingOrders.WOOD_PRICE:
                return (a.upgradeCost.wood || 0) - (b.upgradeCost.wood || 0)
            case BuildingOrders.IRON_PRICE:
                return (a.upgradeCost.iron || 0) - (b.upgradeCost.iron || 0)
            default:
                return a.id - b.id
        }
    })

    return (
        <SimpleGrid columns={[1, 1, 1, 1, condensed ? 1 : 2]} gap={condensed ? "1" : "4"}>
            {sortedBuildings.map((building) => (
                <BuildingComponent key={building.id} {...building} condensed={condensed} />
            ))}
            {[...Array(max - buildings.length)].map((building, index) => (
                <BuildingPlaceholder key={index} withBuildButton={index === 0} />
            ))}
            <Center>
                <BuildModal inactive={true} />
            </Center>
        </SimpleGrid>
    )
})

export default HQ
