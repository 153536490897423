import { FunctionComponent } from "react"
import Link from "./Link"
import { useTheme } from "@chakra-ui/react"
import { Factions } from "../../types"
import { LeaderRole } from "../../api/faction"

interface PlayerNameProps {
    playerId: number
    username: string
    faction?: Factions
    isPremium?: boolean
    leaderRole?: LeaderRole
}

const PlayerName: FunctionComponent<PlayerNameProps> = ({ playerId, username, faction, isPremium, leaderRole }) => {
    const theme = useTheme()
    const factionColor = theme.app.factions[faction!] || "orange.300"

    return (
        <Link to={`/player/${username}-${playerId}`} color={factionColor}>
            {username}
        </Link>
    )
}

export default PlayerName
