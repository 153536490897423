import { makeObservable, computed, observable, action, onBecomeObserved, onBecomeUnobserved, runInAction } from "mobx"
import BaseStore from "./BaseStore"
import RootStore from "./RootStore"
import { queryClient } from "../query_client"
import ReconnectingWebSocket from "reconnecting-websocket"
import { HQInfo } from "../api/game"
import { BuildingType, Factions, UnlockType } from "../types"
import { getKeys } from "../utils"
import { WS_BASE_URL } from "../config"
import { Choice, Survey } from "../api/faction"
import { router } from "../router"
import { MessageType } from "./ChatStore"
import { PlayerActivity } from "../api/player"

export type Notification = {
    id: string,
    type: "achievement" | "unlock" | "message" | "error" | "castle" | "new_game" | "game_completed",
    title: string,
    message?: string
    faction?: Factions
}

export type FactionSocketData = {
    type: 'new_vote'
    id: number
    choices: Choice[]
} | {
    type: 'initial_activities'
    list: PlayerActivity[]
} | {
    type: 'new_activity'
    activity: PlayerActivity
}
export default class SocketStore extends BaseStore {

    notifications: Notification[] = []
    notificationsSocket: ReconnectingWebSocket | null = null
    worldSocket: ReconnectingWebSocket | null = null
    factionSocket: ReconnectingWebSocket | null = null
    factionActivities: PlayerActivity[] = []
    factionActivitiesInitialized: boolean = false
    battleActivities: PlayerActivity[] = []
    battleActivitiesInitialized: boolean = false
    newGamePopup: number | null = null

    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            notifications: observable,
            factionActivities: observable,
            factionActivitiesInitialized: observable,
            battleActivities: observable,
            battleActivitiesInitialized: observable,
            newGamePopup: observable,
            startNotificationsSocket: action,
            stopNotificationsSocket: action,
            startWorldSocket: action,
            stopWorldSocket: action,
            startFactionSocket: action,
            stopFactionSocket: action,
            markAsRead: action,
            addNotification: action,
            setNewGamePopup: action,
        })
        onBecomeObserved(this, "notifications", this.startNotificationsSocket.bind(this))
        onBecomeUnobserved(this, "notifications", this.stopNotificationsSocket.bind(this))
    }

    startNotificationsSocket() {
        // connect to websocket

        if (this.notificationsSocket?.OPEN || !this.rootStore.userStore.player?.userId)
            return


        this.notificationsSocket = new ReconnectingWebSocket(WS_BASE_URL + "notification", ['Token', `${this.rootStore.userStore.apiToken}`], {
            debug: false,
            // WebSocket: createWebSocketClass({
            //     headers: {
            //         Authorization: `Bearer ${this.rootStore.userStore.apiToken}`,
            //     },
            // })
        })
        this.notificationsSocket.addEventListener('message', (event) => {

            // careful, we may not be in the right game
            const gameId = this.rootStore.userStore.gameId

            const data = JSON.parse(event.data)
            const payload = data['payload']

            console.log(payload)
            if (payload['type'] == "quest") {
                queryClient.invalidateQueries({ queryKey: ["quest", gameId] })
            }
            else if (payload['type'] == "achievement") {
                queryClient.invalidateQueries({ queryKey: ["achievements", gameId] })
                this.addNotification(payload)
            }
            else if (payload['type'] == "castle") {
                queryClient.invalidateQueries({ queryKey: ["game", gameId] })
                if (payload['status'] == 'start')
                    this.addNotification(payload)
            }
            else if (payload['type'] == "unlock") {
                this.addNotification(payload)
                queryClient.setQueryData(["hqInfo", gameId], (oldData: HQInfo | undefined) => {
                    if (oldData) {
                        return {
                            ...oldData,
                            unlocks: [...oldData.unlocks, payload['title'] as UnlockType]
                        }
                    }
                    return undefined
                })
            }
            else if (payload['type'] == 'new_game') {
                // const gameId = payload['game_id']
                // queryClient.invalidateQueries({ queryKey: ["games"] })
                // queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
                // queryClient.invalidateQueries({ queryKey: ["config", gameId] })
                // queryClient.invalidateQueries({ queryKey: ["game", gameId] })
                // queryClient.invalidateQueries({ queryKey: ["player", gameId] })

                // this.addNotification(payload)
                this.setNewGamePopup(payload['game_id'])  
                // setTimeout(() => {
                //     router.navigate(`/play/${gameId}`)
                // }, 1000)
            }
            else if (payload['type'] == "game_completed") {
                const gameId = payload['game_id']
                queryClient.invalidateQueries({ queryKey: ["games"] })
                queryClient.invalidateQueries({ queryKey: ["game", gameId] })
                router.navigate(`/games/${gameId}`)
                this.addNotification(payload)
            }


        })
    }

    stopNotificationsSocket() {
        // disconnect from websocket
        this.notificationsSocket?.close()
        this.notificationsSocket = null
    }


    startWorldSocket() {
        // connect to world websocket
        var WSConnectionString = `${WS_BASE_URL}game/${this.rootStore.userStore.gameId}/world_update`
        if (!this.worldSocket?.OPEN)
            this.worldSocket = new ReconnectingWebSocket(WSConnectionString, ['Token', `${this.rootStore.userStore.apiToken}`], {
                debug: false
            })
        this.worldSocket.addEventListener('message', (event) => {
            const data: FactionSocketData = JSON.parse(event.data)
            if (data.type == "initial_activities") {
                runInAction(() => {
                    this.battleActivities = data.list
                    this.battleActivitiesInitialized = true
                })
            }
            else if ("activity" in data) {
                runInAction(() => {
                    // check if activity is already in the list
                    if (!this.battleActivities.some(a => a.id == data.activity.id)) {
                        this.battleActivities = [...this.battleActivities, data.activity].slice(-100)
                    }
                    else {
                        // replace previous activity
                        this.battleActivities = this.battleActivities.map(a => a.id == data.activity.id ? data.activity : a)
                    }
                    this.battleActivities.slice(-100)

                    if (data.activity.x && data.activity.y) {
                        queryClient.setQueryData(["caseActivities", data.activity.x, data.activity.y], (oldData: PlayerActivity[] | undefined) => {
                            if (oldData) {
                                // replace activity or add it if not present
                                if (oldData.some(a => a.id == data.activity.id)) {
                                    return oldData.map(a => a.id == data.activity.id ? data.activity : a)
                                }
                                else {
                                    return [...oldData, data.activity]
                                }
                            }
                            return undefined
                        })
                    }
                })
            }
        })
    }

    stopWorldSocket() {
        // disconnect from world websocket
        this.worldSocket?.close()
        this.worldSocket = null
    }

    startFactionSocket() {
        // connect to faction websocket
        var WSConnectionString = `${WS_BASE_URL}game/${this.rootStore.userStore.gameId}/${this.rootStore.userStore.player!.faction}`
        if (!this.factionSocket?.OPEN)
            this.factionSocket = new ReconnectingWebSocket(WSConnectionString, ['Token', `${this.rootStore.userStore.apiToken}`], {
                debug: false
            })
        this.factionSocket.addEventListener('message', (event) => {

            const data: FactionSocketData = JSON.parse(event.data)
            console.log(data)
            if (data.type == 'new_vote') {
                queryClient.setQueryData(["surveys", this.rootStore.userStore.gameId], (oldData: Survey[] | undefined) => {
                    // find relevant survey and update it
                    const survey = oldData?.find(s => s.id == data.id)
                    if (survey) {
                        return oldData?.map(s => {
                            if (s.id == data.id) {
                                return {
                                    ...s,
                                    choices: data.choices,
                                }
                            }
                            return s
                        })
                    }

                })
            }
            else if (data.type == "initial_activities") {
                console.log("initial_activities", data.list)
                runInAction(() => {
                    this.factionActivities = data.list
                    this.factionActivitiesInitialized = true
                })
            }
            else if (data.type == "new_activity") {
                runInAction(() => {
                    this.factionActivities = [...this.factionActivities, data.activity].slice(-100)
                })
            }
        })

    }

    stopFactionSocket() {
        // disconnect from faction websocket
        this.factionSocket?.close()
        this.factionSocket = null
    }


    /** send a message to a channel */
    leaderPing(x: number, y: number, pingType: "attack" | "defend") {
        // const faction = this.rootStore.userStore.player!.faction
        this.factionSocket?.send(JSON.stringify({ type: "ping", x, y, ping_type: pingType }))
        const player = this.rootStore.userStore.player!
        this.rootStore.chatStore.sendMessage(`${this.rootStore.userStore.gameId}_${player.faction}`, `${x}:${y}`, pingType as MessageType)
    }


    addNotification(notification: Notification) {
        // add notification to list
        this.notifications.push(notification)
    }

    markAsRead(notificationId: string) {
        // mark notification as read
        this.notifications = this.notifications.filter(n => n.id != notificationId)
    }

    setNewGamePopup(value: number | null) {
        this.newGamePopup = value
    }

    debug_createNotifications() {
        for (let unlock of getKeys(UnlockType)) {
            this.addNotification({
                id: "unlock_" + unlock,
                type: "unlock",
                title: unlock,
            })
        }

        this.addNotification({
            id: "5",
            type: "achievement",
            title: "SOLDIERS_SENT_1",
        })

    }

}
