import { Box, Center, Container, HStack, Link, List, Stack, Switch, Text, useToast } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { useConfig, useGameId, useHQInfo, useResources } from "../../../hooks/query"
import Button from "../../common/Button"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { HQInfo, setSleepMode, setTicksSpeed, useTicks } from "../../../api/game"
import HelpText from "../../common/HelpText"


export const useSleepMutation = () => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const toast = useToast()
    return useMutation({
        mutationFn: (value: boolean) => setSleepMode(gameId, value),
        // onMutate: (value) => {
        // queryClient.setQueryData(["hqInfo", gameId], (prev: HQInfo) => {
        //     return {
        //         ...prev,
        //         hq: {
        //             ...prev.hq,
        //                 sleepMode: value,
        //             },
        //         }
        //     })
        // },
        onSuccess: (data, variables, context) => {
            queryClient.setQueryData(["hqInfo", gameId], (prev: HQInfo) => {
                return {
                    ...prev,
                    hq: {
                        ...prev.hq,
                        sleepMode: data.sleep_mode,
                    },
                }
            })
        },
        onError: (error, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
            toast({
                title: "An error occured while changing sleep mode.",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        },
    })
}

const SleepMode: FunctionComponent = () => {
    const queryClient = useQueryClient()
    const gameId = useGameId()
    const toast = useToast()
    const config = useConfig()

    const { data } = useHQInfo()
    const resources = useResources()

    const sleepMutation = useSleepMutation()

    const ticksSpeedMutation = useMutation({
        mutationFn: (value: number) => setTicksSpeed(gameId, value),
        onMutate: (value) => {
            queryClient.setQueryData(["hqInfo", gameId], (prev: HQInfo) => {
                return {
                    ...prev,
                    hq: {
                        ...prev.hq,
                        ticksSpeed: value,
                    },
                }
            })
        },
        onError: (error, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
            toast({
                title: "An error occured while changing ticks speed.",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        },
    })

    // const ticksMutation = useMutation({
    //     mutationFn: (value: number) => useTicks(gameId, value),
    //     onSuccess: (data) => {
    //         queryClient.setQueryData(["gatherResources", gameId], data)
    //     },
    // })

    const showTicks = !data?.hq.sleepMode && resources.ticks > 0

    if (!config?.modeConfig.sleep_mode) return null

    return (
        <Box fontSize="xs" whiteSpace={"nowrap"}>
            <Stack gap="0">
                <HStack>
                    <Text>Sleep mode</Text>
                    <Switch
                        id="sleep-mode"
                        isChecked={data?.hq.sleepMode}
                        colorScheme="orange"
                        onChange={(e) => {
                            sleepMutation.mutate(e.target.checked)
                        }}
                    />
                    <HelpText
                        tooltip={
                            <Stack>
                                <Text>
                                    When you activate the sleep mode, you accumulates "ticks" instead of earning
                                    resources.
                                </Text>
                                <Text>
                                    When leaving the sleep mode, you will be able to speed resources production by
                                    adjusting the ticks speed. For example, if you have a production of 2 woods at x5
                                    speed. You will earn 10 woods per gather at the cost of 4 stored ticks.
                                    If you have none ticks left, you will earn 2 woods per gather.
                                </Text>
                                <Text>
                                    You can't earn more than 8 hours of accumulated ticks (1440 ticks). 12 hours for
                                    premium users.
                                </Text>
                            </Stack>
                        }
                    ></HelpText>
                </HStack>
                <HStack gap="0">
                    <Text mr="1">Ticks : {resources.ticks}</Text>
                    <Box visibility={showTicks ? "visible" : "hidden"}>
                        <Button
                            size="sm"
                            m="0"
                            tooltip={`Normal speed, you don't spend ticks`}
                            onClick={() => ticksSpeedMutation.mutate(1)}
                            visibility={showTicks ? "visible" : "hidden"}
                            pressed={data?.hq.ticksSpeed === 1}
                        >
                            x1
                        </Button>

                        <Button
                            size="sm"
                            m="0"
                            tooltip={`Double speed, you spend 1 stored tick every tick.`}
                            onClick={() => ticksSpeedMutation.mutate(2)}
                            visibility={showTicks ? "visible" : "hidden"}
                            pressed={data?.hq.ticksSpeed === 2}
                        >
                            x2
                        </Button>
                        <Button
                            size="sm"
                            m="0"
                            tooltip={`Ultra speed, you spend 4 stored ticks every tick.`}
                            onClick={() => ticksSpeedMutation.mutate(5)}
                            visibility={showTicks ? "visible" : "hidden"}
                            pressed={data?.hq.ticksSpeed === 5}
                        >
                            x5
                        </Button>
                    </Box>
                </HStack>
            </Stack>
        </Box>
    )
}

export default SleepMode
