import { Box, Heading, Input, List, ListItem, SimpleGrid, Text, Link as ChakraLink, HStack, Button } from "@chakra-ui/react"
import { FunctionComponent, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { usePlayer } from "../hooks/query"
import { useQuery } from "@tanstack/react-query"
import { getPlayerLogs, getPlayersWithSameIps } from "../api/player"
import DateComponent from "../components/common/DateComponent"
import { Link } from "react-router-dom"
import Panel from "../components/common/Panel"
import MessageComponent from "../components/chat/Message"
import { Message } from "../store/ChatStore"
import Activity from "../components/activities/Activity"

const PlayerLogsPage: FunctionComponent = () => {
    const player = usePlayer()
    const [otherPlayerIdInput, setOtherPlayerIdInput] = useState<string | null>(null)
    const [otherPlayerId, setOtherPlayerId] = useState<number | null>(null)
    const otherPlayerIdInputRef = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()
    if (!player?.isAdmin) {
        navigate("/")
    }

    const { username } = useParams()
    const id = parseInt(username?.split("-").slice(-1)[0] ?? "0")
    const { data, isLoading, error } = useQuery({
        queryKey: ["playerLogs", id],
        queryFn: () => getPlayerLogs(id!),
    })

    const { data: otherPlayerLogs } = useQuery({
        queryKey: ["playerLogs", otherPlayerId],
        queryFn: () => getPlayerLogs(otherPlayerId!),
        enabled: !!otherPlayerId,
    })

    const { data: sameIps } = useQuery({
        queryKey: ["playersWithSameIps"],
        queryFn: () => getPlayersWithSameIps(),
    })

    // fusionne activités et messages chat
    const playerActivities = []
    if (data) {
        playerActivities.push(...data?.activities)
        playerActivities.push(...(data?.chatMessages ?? []))
    }

    const otherPlayerActivities = []
    if (otherPlayerLogs) {
        otherPlayerActivities.push(...(otherPlayerLogs?.chatMessages ?? []))
        otherPlayerActivities.push(...(otherPlayerLogs?.activities ?? []))
    }

    const activities = [...playerActivities, ...otherPlayerActivities]
    activities.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    console.log(activities)
    return (
        <Box p="4">
            <Heading as="h1">Player logs</Heading>

            <SimpleGrid columns={2} spacing="4">
                <Panel>
                    <Heading as="h2">IP addresses</Heading>
                    <List>
                        {data?.ipAddresses.map((ip) => (
                            <ListItem key={ip}>{ip}</ListItem>
                        ))}
                    </List>
                </Panel>

                <Panel maxHeight="500px" overflow="auto">
                    <Heading>Players sharing IP</Heading>
                    {sameIps?.map((sameIp) => (
                        <Box key={sameIp.ip_address}>
                            <Text>{sameIp.ip_address}</Text>
                            <List>
                                {sameIp.players.map((player) => (
                                    <ListItem key={player.id}>
                                        <HStack justify="space-between">
                                            <Link to={`/player/${player.username}-${player.id}/logs`}>
                                                {player.username}
                                            </Link>
                                            <ChakraLink onClick={() => setOtherPlayerId(player.id)}>Compare</ChakraLink>
                                        </HStack>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    ))}
                </Panel>
            </SimpleGrid>

            <Heading as="h2">Activities</Heading>

            <Text>Compare to another player</Text>
            <HStack>
                <Input
                    type="number"
                    ref={otherPlayerIdInputRef}
                />
                <Button onClick={() => setOtherPlayerId(parseInt(otherPlayerIdInputRef.current?.value ?? "0"))}>Ok</Button>
            </HStack>
            <List mt="4">
                {activities.map((activity) => (
                    <ListItem key={activity.id}>
                        <HStack>
                            <Text fontSize="xs" color="orange.200" whiteSpace="nowrap" width="150px">
                                <DateComponent date={activity.created_at * 1000} showTime />
                            </Text>
                            <HStack p="1" width="100%" backgroundColor={activity.player.id === id ? "blue.800" : "red.800"} color={"content" in activity ? "orange.300" : "orange.100"}>
                                {"type" in activity && <Activity activity={activity} showTime={false} />}
                                {"content" in activity && <Box>{activity.content}</Box>}
                            </HStack>
                        </HStack>
                    </ListItem>
                ))}
            </List>


        </Box>
    )
}

export default PlayerLogsPage
