import BaseStore from "./BaseStore"
import RootStore from "./RootStore"
import { queryClient } from "../query_client"
import ReconnectingWebSocket from "reconnecting-websocket"
import { Projects } from "../api/projects"
import { Factions, ProjectType } from "../types"
import { WS_BASE_URL } from "../config"
import { action, makeObservable, observable, runInAction } from "mobx"
import { PlayerActivity } from "../api/player"



type ProjectsSocketData = {
    id: number,
    name: ProjectType,
    workers: number,
    type: "permanent",
    completed: boolean,
    faction: Factions
} | {
    id: number,
    faction: Factions,
    workers: number,
    type: "event",
    activity: PlayerActivity
} | {
    type: 'initial_activities'
    list: PlayerActivity[]
}

export default class ProjectsStore extends BaseStore {

    // notifications: Notification[] = []
    projectsSocket: ReconnectingWebSocket | null = null
    selectedProject: number | null = null
    projectsActivities: PlayerActivity[] = []
    projectsActivitiesInitialized: boolean = false

    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            projectsActivities: observable,
            selectedProject: observable,
            setSelectedProject: action,
            projectsActivitiesInitialized: observable,
        })
        // onBecomeObserved(this, "projectsSocket", this.startProjectsSocket.bind(this))
        // onBecomeUnobserved(this, "projectsSocket", this.stopProjectsSocket.bind(this))
    }


    startProjectsSocket() {
        // connect to projects websocket
        if (this.projectsSocket?.OPEN || !this.rootStore.userStore.player?.userId)
            return

        const gameId = this.rootStore.userStore.gameId
        this.projectsSocket = new ReconnectingWebSocket(`${WS_BASE_URL}game/${gameId}/projects`, ['Token', `${this.rootStore.userStore.apiToken}`], {
            debug: false
        })
        this.projectsSocket.addEventListener('message', (event) => {
            console.log("receive message")
            const payload: ProjectsSocketData = JSON.parse(event.data)

            const playerFaction = this.rootStore.userStore.player?.faction
            if (payload.type === "permanent" && payload.faction !== playerFaction)
                return

            if (payload.type === "initial_activities") {
                runInAction(() => {
                    this.projectsActivities = payload.list
                    this.projectsActivitiesInitialized = true
                })
            }
            else {
                queryClient.setQueryData(['projects', gameId], (old: Projects | undefined) => {

                    if (old === undefined)
                        return old

                    let permanent = old.permanent
                    if (payload.type === "permanent" && payload.completed) {
                        permanent = []
                        const completedProjects = old.permanent.filter(project => project.completed).map(project => project.id)
                        completedProjects.push(payload.id)
                        for (const project of old.permanent) {
                            permanent.push({
                                ...project,
                                completed: completedProjects.includes(project.id),
                                // check if all requires are completed
                                unlocked: project.requires?.every(req => completedProjects.includes(req)) || !project.requires?.length,
                            })
                        }
                    }


                    return {
                        permanent: permanent.map(project => {
                            if (payload.type === "permanent" && project.id === payload.id) {
                                return {
                                    ...project,
                                    workers: payload.workers
                                }
                            }
                            return project
                        }),
                        event: old.event.map(project => {
                            if (payload.type === "event" && project.id === payload.id) {
                                return {
                                    ...project,
                                    workers: {
                                        ...project.workers,
                                        [payload.faction]: payload.workers
                                    }
                                }
                            }
                            return project
                        }),
                        last: old.last
                    }
                })
            }
            if ('activity' in payload) {
                runInAction(() => {
                    // check if activity is already in the list
                    if (!this.projectsActivities.some(a => a.id == payload.activity.id)) {
                        this.projectsActivities = [...this.projectsActivities, payload.activity].slice(-100)
                    }
                    else {
                        // replace previous activity
                        this.projectsActivities = this.projectsActivities.map(a => a.id == payload.activity.id ? payload.activity : a)
                    }
                    this.projectsActivities.slice(-100)
                })
            }
        })
    }

    stopProjectsSocket() {
        // disconnect from websocket
        this.projectsSocket?.close()
        this.projectsSocket = null
    }

    setSelectedProject(value: number) {
        // set selected project
        this.selectedProject = value
    }


}
