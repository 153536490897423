import { useQuery } from "@tanstack/react-query";
import { Factions, ProjectType, ResourcesList, SpecializationType, UnlockType } from "../types";
import { authApi } from "./base";
import { Mod } from "./game";

export async function pickSpecialization(gameId: number, specialization: SpecializationType) {
    const response = await authApi.post(`/game/${gameId}/specialization/pick`, {}, { params: { specialization } })
    return response.data
}



export interface Project {
    id: number
    name: ProjectType
    tier: number
    type: "building" | "ui" | "gain"
    cost: number
    workers: number
    baseEffects: Mod[]
    unlock?: UnlockType
    gain: ResourcesList
    requires?: number[]
    completed: boolean
    unlocked: boolean
}

export interface EventProject {
    id: number
    name: string
    workers: {
        [Factions.RED]?: number
        [Factions.BLUE]?: number
        [Factions.GREEN]?: number
        [Factions.YELLOW]?: number
    }
    hiddenProgress: boolean
    baseEffects: Mod[]
    gain: ResourcesList
    endDate: Date
}

export type FinishedProject = {
    id: number
    name: string
    first: Factions
    second: Factions
    third: Factions
    workers: {
        [Factions.RED]?: number
        [Factions.BLUE]?: number
        [Factions.GREEN]?: number
        [Factions.YELLOW]?: number
    }
    baseEffects: Mod[]
    gain: ResourcesList
    bonus: number
    created: Date
}

export type ProjectsTree = {
    id: string
    children: ProjectsTree[]
}
export interface Projects {
    permanent: Project[]
    event: EventProject[]
    last: FinishedProject[]
}


export async function getProjects(gameId: number): Promise<Projects> {
    // get projects
    const talents = await authApi.get(`/game/${gameId}/projects/list`)
    return talents.data
}

export async function getOldProjects(gameId: number): Promise<FinishedProject[]> {
    // get old projects
    const talents = await authApi.get(`/game/${gameId}/projects/old`)
    return talents.data
}


export async function work(gameId: number, id: number, workers: number) {
    const response = await authApi.post(`/game/${gameId}/projects/work`, {}, { params: { id, workers } })
    return response.data
}

export async function event_work(gameId: number, id: number, workers: number) {
    const response = await authApi.post(`/game/${gameId}/projects/event_work`, {}, { params: { id, workers } })
    return response.data
}