import { useMutation, useQuery } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useState } from "react"
import { useNavigate } from "react-router-dom"
import { anonLogin, getLoginStatus } from "../api/player"
import { useStore } from "../store"
import { Box, Center, Heading, Link, Stack, Text, Input } from "@chakra-ui/react"
import Button from "../components/common/Button"
import Panel from "../components/common/Panel"
import LoginButtons from "../components/auth/LoginButtons"
import Loading from "../components/common/Loading"
import Cookies from "js-cookie"
const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN

const Login: FunctionComponent = observer(() => {
    const { userStore } = useStore()
    const navigate = useNavigate()

    const [code, setCode] = useState<string>("")

    const { data: loginStatus, isLoading } = useQuery({ queryKey: ["loginStatus"], queryFn: getLoginStatus })

    const mutation = useMutation({
        mutationFn: () => {
            return anonLogin()
        },
        onSuccess: (data) => {
            const token = data.data.api_token
            if (token) userStore.setApiToken(token)
            navigate("/games")
        },
    })

    const checkCode = (code: string) => {
        setCode(code)
        if (code === "welcome") {
            Cookies.set("invite_code", code, { expires: 7, domain: COOKIE_DOMAIN })
        }
    }

    const anonymousLogin = () => {
        mutation.mutate()
    }

    return (
        <Box p="4">
            <Center>
                {isLoading ? (
                    <Loading />
                ) : (
                    <Stack spacing={3} justifyContent={"center"} alignItems={"center"}>
                        <Heading>Factions</Heading>

                        <Panel variant="withBorder" p="2" maxW="600px">
                            <Text variant="negative">
                                Due to limited server capacity, there is a limit to the amount of players the server can
                                handle.
                            </Text>
                            {loginStatus!.available && !code && (
                                <Text color="green.700">
                                    The server is currently open to new players ({loginStatus?.accountsLeft} accounts
                                    left).
                                </Text>
                            )}
                            {!loginStatus!.available && (
                                <>
                                    <Text variant="negative" mb="2">
                                        The server is currently full. Please try again later.
                                    </Text>
                                    <Text>
                                        Or use an invitation code to unlock account creation :{" "}
                                        <Input
                                            value={code}
                                            onChange={(e) => checkCode(e.target.value)}
                                            placeholder="code"
                                            color="orange.200"
                                            bgColor="orange.700"
                                            _focus={{ borderColor: "orange.500", boxShadow: "none" }}
                                            _placeholder={{ opacity: 0.5, color: "white" }}
                                        />
                                    </Text>
                                    <Text>
                                        You may get a code in the{" "}
                                        <Link href="https://discord.gg/DsKSmetnqV" target="_blank" color="orange.100">
                                            Discord
                                        </Link>{" "}
                                        server.
                                    </Text>
                                </>
                            )}
                        </Panel>

                        <Stack justifyContent={"center"} alignItems={"center"}>
                            {loginStatus?.available ? (
                                <Text>Please log in with Steam, Discord or Google to keep track of your progress.</Text>
                            ) : (
                                <Text>If you already have an account, you can log in.</Text>
                            )}

                            <LoginButtons />

                            <Stack mt="50px">
                                <Panel variant="withBorder" p="2" maxW="400px">
                                    <Text variant="negative">
                                        If you prefer not to sign up with an email or link other accounts, you have the
                                        option to log in anonymously. You will still enjoy the same core game
                                        functionality and face no competitive disadvantage. However, your progress is
                                        tied to your browser cache and may be lost. You also won't be able to choose a
                                        name.
                                    </Text>
                                </Panel>
                                <Center>
                                    <Button
                                        onClick={() => anonymousLogin()}
                                        w={"200px"}
                                        colorScheme="red"
                                        title="Anonymous log in"
                                        isDisabled={!loginStatus?.available}
                                    >
                                        Anonymous log in
                                    </Button>
                                </Center>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Center>
        </Box>
    )
})

export default Login
