let intersectionObserver: IntersectionObserver | null = null
let intersectionObserverOptions = {}
const subscribers = new WeakMap()

export const handleIntersections = (entries: IntersectionObserverEntry[]) =>
    entries.forEach((entry) => {
        const maybeEntry = subscribers.get(entry.target)

        if (maybeEntry) {
            maybeEntry.call(null, entry)
        }
    })

export const getIntersectionObserver = () => {
    if (!intersectionObserver) {
        intersectionObserver = new IntersectionObserver(
            handleIntersections,
            intersectionObserverOptions
        )
    }

    return intersectionObserver
}

export const setIntersectionObserverOptions = (options: IntersectionObserverInit) => {
    if (intersectionObserver) {
        return
    }

    intersectionObserverOptions = options
}

export const watch = (domNode: HTMLElement | null, callback: IntersectionObserverCallback) => {
    if (!domNode || subscribers.has(domNode)) {
        return
    }

    subscribers.set(domNode, callback)
    getIntersectionObserver().observe(domNode)

    return () => unwatch(domNode)
}

export const unwatch = (domNode: HTMLElement | null) => {
    if (intersectionObserver && domNode)
        intersectionObserver.unobserve(domNode)
    if (domNode)
        subscribers.delete(domNode)
}

export const getSubscribers = () => subscribers

