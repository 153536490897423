import { Box, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import Players from "../components/faction/Players"
import Surveys from "../components/survey/Surveys"
import Activity from "../components/activities/Activity"
import { useStore } from "../store"
import Panel from "../components/common/Panel"
import Activities from "../components/activities/Activities"

const FactionActivities = observer(() => {
    const { socketStore } = useStore()
    return (
        <Panel>
            <Heading as="h3" size="md" mb="2">
                Logs
            </Heading>
            <Activities
                list={socketStore.factionActivities}
                maxHeight="20rem"
                initialized={socketStore.factionActivitiesInitialized}
            />
        </Panel>
    )
})

const FactionPage: FunctionComponent = observer(() => {
    return (
        <Box p="0">
            <SimpleGrid columns={[1, 1, 2]} spacing={0}>
                <FactionActivities />
                <Panel>
                    <Heading as="h3" size="md" mb="2">
                        Events
                    </Heading>
                    <Text>Coming soon (WIP)</Text>
                </Panel>
            </SimpleGrid>
            <Surveys />
            <Players />
        </Box>
    )
})

export default FactionPage
